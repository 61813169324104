//Polyfill
import "core-js";
import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)
//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;

//bootstrapのmodalを読み込み
import "/node_modules/bootstrap/js/dist/modal";

$(document).ready(function () {
  // スクロールイベントを監視
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    var threshold = 200; // スクロール量の閾値

    // スクロール量が閾値を超えたら、"active"クラスを追加
    if (scroll >= threshold) {
      $(".header").addClass("active");
    } else {
      $(".header").removeClass("active");
    }
  });

  // ページ最上部に戻ったら、"active"クラスを削除
  $(window).scroll(function () {
    if ($(window).scrollTop() == 0) {
      $(".header").removeClass("active");
    }
  });

  // スマホ時 メニュー開閉
  var openBtn = $(".header--btn");
  var closeBtn = $(".close");
  var gNav = $(".header--nav");
  openBtn.click(function (e) {
    e.preventDefault();
    if ($(this).hasClass("open")) {
      gNav.removeClass("open");
      $(this).removeClass("open");
    } else {
      gNav.addClass("open");
      $(this).addClass("open");
    }
  });
  $(closeBtn).on("click", function () {
    $(gNav).removeClass("open");
  });

  $(".demolition-faq-box--q").click(function () {
    $(this).next(".demolition-faq-box--wrap").slideToggle();
    $(this).toggleClass("open");
  });
});
